export const language = {
    languageName: "Português",

    app: {
        name: "Criador de LRC",
        fullname: "Criador de LRC de Akari",
    },

    header: {
        home: "início",
        editor: "editor",
        synchronizer: "sincronizador",
        gist: "Github Gist",
        preferences: "preferências",
    },

    audio: {
        loadAudio: "carregar áudio",
        play: "play",
        pause: "pausar",
        replay5s: "repetir 5s",
        forward5s: "avançar 5s",
        resetRate: "redefinir a taxa de reprodução",
        error: [
            "erro de áudio",
            "áudio abortado pelo usuário",
            "erro de rede de áudio",
            "erro de decodificação de áudio",
            "áudio não suportado",
        ],
    },

    loadAudio: {
        file: "Arquivo",
        url: "URL",
        loadFile: "Clique aqui ou solte um arquivo de áudio nesta página",
    },

    home: {
        tips: "Dicas",
        tipForLyricText: "Navegue até a página do editor e cole o texto da letra.",
        tipForUploadAudio: "Clique no botão inferior esquerdo para carregar o arquivo de áudio.",
        tipForSynchronizer: "Navegue até a página do sincronizador e faça seu lrc :)",
        tipTopLeft: "Clique aqui para voltar a esta página",
        tipTopRight: "Clique aqui para navegar para outra página",
        tipBottomLeft: "carregue o áudio aqui e controle o áudio",
        tipBottomRight: "altere a taxa de reprodução aqui",
    },

    editor: {
        metaInfo: "informações meta",
        album: "álbum",
        title: "título",
        artist: "artista",
        uploadText: "enviar texto",
        copyText: "selecione tudo e copie",
        downloadText: "baixar",
        saveToGist: "salvar no Github Gist",
        saveFileName: "Nome do arquivo",
        utils: "lrc utils",
    },

    gist: {
        newTokenTip: "o Criador de LRC usa o Token do Github para recolher e armazenar dados para o Github Gist",
        newTokenButton: "Gerar um novo Token do Github",
        newGistTip: "Criar um novo repositório Gist ou atribuir um id Gist existente",
        newGistRepoButton: "Criar um novo Repositório Gist",
        gistIdPlaceholder: "Ou atribuir um id Gist existente",
        info: "informação",
        clearTokenAndGist: "Limpar Token e id Gist",
    },

    preferences: {
        version: "Versão",
        commitHash: "Commit hash",
        updateTime: "Tempo de atualização",
        repo: "Repositório",
        help: "Ajuda e dicas",
        language: "Linguagem",
        builtInAudio: "Use o player de áudio integrado do navegador",
        spaceButton: "Tecla de espaço na tela",
        themeColor: "Cor do tema",
        lrcFormat: "Ajuste de formato LRC",
        fixed: "Data-de-tempo fixo",
        leftSpace: "Espaço esquerdo",
        rightSpace: "Espaço direito",
        clearCache: "Limpar cache",
        themeMode: {
            label: "Modo de tema",
            auto: "auto",
            light: "claro",
            dark: "escuro",
        },
    },

    notify: {
        audioLoaded: "Áudio carregado",
    },
};
